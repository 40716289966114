import store from "@/state/store";

export default [
  {
    path: "/",
    name: "default",
    meta:{
      title: 'لوحة التحكم'
    },
    component: () => import("./views/dashboards/default"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("./views/account/login"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
      title: "تسجيل الدخول"
    },
  },
 
 
  {
    path: "/404",
    name: "404",
    component: require("./views/utility/404").default,
  },

  {
    path: "/researches",
    name: "الأبحاث",
    meta:{
      title: 'الأبحاث'
    },
    component: () => import("./views/researches/Index"),

  },

  {
    path: "/researches/:id/edit",
    name: " تعديل حالة البحث",
    meta:{
      title: ' تعديل حالة البحث'
    },
    component: () => import("./views/researches/Edit"),
  },
  {
    path: "/researches/:id/show",
    name: " عرض البحث",
    meta:{
      title: ' عرض البحث'
    },
    component: () => import("./views/researches/Show"),
  },
  {
    path: "/orders-list",
    name: "الطلبات",
    meta:{
      title: 'الطلبات'
    },
    component: () => import("./views/orders-list/Index"),

  },

  {
    path: "/orders-list/:id/show",
    name: "  عرض بيانات الطلب",
    meta:{
      title: ' عرض بيانات الطلب'
    },
    component: () => import("./views/orders-list/Show"),
  },
  {
    path: "/services",
    name: " الخدمات",
    meta:{
      title: 'الخدمات'
    },
    component: () => import("./views/services/Index"),
  },
  {
    path: "/services/create",
    name: " إضافة خدمة ",
    meta:{
      title: 'اضافة خدمة'
    },
    component: () => import("./views/services/Create"),
  },
  {
    path: "/services/:id/show",
    name: " تفاصيل الخدمة",
    meta:{
      title: 'تفاصيل الخدمة'
    },
    component: () => import("./views/services/Show"),
  },
  {
    path: "/services/:id/edit",
    name: " تعديل الخدمة",
    meta:{
      title: 'تعديل الخدمة'
    },
    component: () => import("./views/services/Edit"),
  },
  {
    path: "/magazines",
    name: " المجلات",
    meta:{
      title: 'المجلات'
    },
    component: () => import("./views/magazines/Index"),
  },
  {
    path: "/magazines/create",
    name: " إضافة مجلة ",
    meta:{
      title: 'اضافة مجلة'
    },
    component: () => import("./views/magazines/Create"),
  },
  {
    path: "/magazines/:id/show",
    name: " تفاصيل المنتج",
    meta:{
      title: 'تفاصيل المجلة'
    },
    component: () => import("./views/magazines/Show"),
  },
  {
    path: "/magazines/:id/edit",
    name: " تعديل المجلة",
    meta:{
      title: 'تعديل المجلة'
    },
    component: () => import("./views/magazines/Edit"),
  },
  {
    path: "/consultants",
    name: " متخصص الخدمات",
    meta:{
      title: 'متخصصي الخدمات'
    },
    component: () => import("./views/consultants/Index"),
  },
  {
    path: "/consultants/create",
    name: " إضافة متخصص ",
    meta:{
      title: 'اضافة متخصص'
    },
    component: () => import("./views/consultants/Create"),
  },
  {
    path: "/consultants/:id/show",
    name: " تفاصيل متخصص",
    meta:{
      title: ' عرض  متخصص الخدمة'
    },
    component: () => import("./views/consultants/Show"),
  },
  {
    path: "/consultants/:id/edit",
    name: " تعديل بيانات متخصص الخدمة",
    meta:{
      title: 'تعديل بيانات متخصص الخدمة'
    },
    component: () => import("./views/consultants/Edit"),
  },
  {
    path: "/editorial-boards",
    name: "  الهيئة التحريرية",
    meta:{
      title: 'الهيئة التحريرية'
    },
    component: () => import("./views/editorial-boards/Index"),
  },
  {
    path: "/editorial-boards/create",
    name: "اضافة عضو   ",
    meta:{
      title: 'اضافة عضو هيئة '
    },
    component: () => import("./views/editorial-boards/Create"),
  },
  {
    path: "/editorial-boards/:id/show",
    name: " تفاصيل ",
    meta:{
      title: ' عرض بيانات عضو التحرير  '
    },
    component: () => import("./views/editorial-boards/Show"),
  },
  {
    path: "/editorial-boards/:id/edit",
    name: " تعديل بيانات متخصص الخدمة",
    meta:{
      title: 'تعديل بيانات متخصص الخدمة'
    },
    component: () => import("./views/editorial-boards/Edit"),
  },
  //terms
  {
    path: "/terms",
    name: "  الشروط والأحكام",
    meta:{
      title: 'الشروط والأحكام'
    },
    component: () => import("./views/terms/Index"),
  },
  {
    path: "/terms/create",
    name: "اضافة الشروط   ",
    meta:{
      title: 'اضافة  الشروط '
    },
    component: () => import("./views/terms/Create"),
  },
  
  {
    path: "/terms/:id/edit",
    name: " تعديل  الشروط",
    meta:{
      title: 'تعديل   الشروط'
    },
    component: () => import("./views/terms/Edit"),
  },

];